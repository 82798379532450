import React, { useCallback } from "react";
import { withRouter } from "react-router-dom";
import db from '../firebase/setup';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Button, 
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Box, 
} from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import Layout from '../layout/Layout';

import { Formik } from 'formik';
import * as Yup from "yup";

const formSchema = Yup.object({
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string("")
    .required("Enter your password"),
});

const useStyles = makeStyles(theme => ({
  boxTypo: {
    backgroundColor: '#1e2935',
    padding: '2%',
    color: 'white',
    textAlign: 'left',
  }
  }));

const Login = ({ history }) => {
  const classes = useStyles();

  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await db
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        history.push("/");
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  return (
  <div >  
    <Layout>
    <Box
      display="flex"
      alignItems="center"
      height="350px">
      <Grid container justify="center">
        <Grid item xs={12} sm={5}>
          <Card >
            <CardHeader
              title="Login"
              className={classes.boxTypo}
            >
            </CardHeader>
            <Formik
              initialValues={{ email: '', password: '' }}
              validationSchema={formSchema}
            >
            {props => {
                const {
                errors,
                handleBlur,
                handleChange,
                handleReset,
                isValid,
                touched,
                values,
              } = props;
              
              return(
                <form onReset={handleReset} onSubmit={handleLogin}>
                <CardContent>
                  <TextField
                    id="email"
                    name="email"
                    value={values.email}
                    type="email"
                    onBlur={handleBlur('email')}
                    onChange={handleChange('email')}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email ? errors.email : ""}
                    label="Email"
                    fullWidth
                    InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    )
                    }} 
                  />
                  <TextField
                    id="password"
                    name="password"
                    value={values.password}
                    type="password"
                    onBlur={handleBlur('password')}
                    onChange={handleChange('password')}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password ? errors.password : ""}
                    label="Password"
                    fullWidth
                    InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    )
                    }}
                  />
                </CardContent>  
                <CardActions>
                  <Button 
                  color="primary"
                  disabled={!isValid}
                  type="submit" 
                  variant="text" 
                  >Login</Button>
                </CardActions>   
                </form>);}}
          </Formik>
          </Card>
        </Grid>
      </Grid>
    </Box>
    </Layout>
  </div>
  );
};

export default withRouter(Login);