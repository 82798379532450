import { db } from './setup';

export async function createRow(data) {
    return await db
        .collection('mailboxes')
        .doc()
        .set(data);
}

export async function deleteRow(id) {
    return await db
        .collection('mailboxes')
        .doc(id)
        .delete();
}

export async function updateRow(id, data) {
    return await db
        .collection('mailboxes')
        .doc(id)
        .update(data);
}