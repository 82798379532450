import React, {Component} from 'react';
import { AuthContext } from '../context/auth';
import Typography from '@material-ui/core/Typography';

class Root extends Component {
    render() {
        const {
            children,
        } = this.props;

        const {
            authReady,
        } = this.context;
        
        if (!authReady) {
            return <div>
            <Typography variant="body1" gutterBottom>Loading...</Typography>
            
            </div>
        }

        return children;
    }
}

Root.contextType = AuthContext;

export default Root;