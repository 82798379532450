import React from 'react';
import { Typography } from '@material-ui/core';
import Layout from '../layout/Layout';

const Home = () => {
    return (
    <Layout>
        <Typography variant="h1" style={{color: 'white'}}>Home</Typography>

    </Layout>
    )
}

export default Home;