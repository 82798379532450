import React from 'react';
import Layout from '../layout/Layout';
import { Typography } from '@material-ui/core';


const Page404 = () => {
    return (
        <Layout>
           <Typography variant="h1" component="h2">
                Page 404, This Route doesn't exist
            </Typography>
        </Layout>
    )
}


export default Page404;