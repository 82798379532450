import React, { Fragment } from 'react';
import { makeStyles, Box, Grid, Typography, List, ListItem, ListItemIcon, ListItemText, Divider} from '@material-ui/core';
import { Room, Call, Email } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

import logoW from '../assets/LogoCFHBlanco.png';

const useStyles = makeStyles({
  footerBk: {
    backgroundColor: '#1e2935',
    padding: '20px',
    color: 'white',
  },
  logoW: {
    maxWidth: 80,
  },
  hrFooter: {
    width: '80px',
    border: '0.5px solid #FFCC00',
  },
  iconFooter:{
    color: '#FFCC00',
  },
  palette:{
    secondary: '#87bd37',
  },
  gridF:{
    textAlign: 'center',
  },
  secondText:{
    color: '#c2c2c2',
  }
});

export default function Footer() {
  const classes = useStyles();

  return (
    <section id="footer">
      <Fragment>
        <Box className={classes.footerBk} display="flex" flexWrap="wrap" flexDirection="row" >
          <Grid container spacing={2} >
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Contact Us</Typography>
              <hr className={classes.hrFooter} id="hrFooter" align="left" />
              <Typography component="span" variant="body2">
                <List component="nav" aria-label="main mailbox folders">
                  <ListItem button component="a" href="https://goo.gl/maps/J7KgCUQw8GWHmFnYA">
                    <ListItemIcon className={classes.iconFooter}>
                      <Room fontSize="large"/>
                    </ListItemIcon>
                    <ListItemText primary={
                      <Fragment>
                        <Typography component="span" variant="body1">14350 nw 56 ct unit 109</Typography><br/>
                        <Typography component="span" variant="body1">Opa Locka FL 33054</Typography>
                      </Fragment>
                    } />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon className={classes.iconFooter}>
                      <Call fontSize="large"/>
                    </ListItemIcon>
                    <ListItemText primary="+1(786)224.3552" />
                  </ListItem>
                  <ListItem button component="a" href="mailto:info@cfhlogistics.com">
                  <ListItemIcon className={classes.iconFooter}>
                      <Email fontSize="large"/>
                    </ListItemIcon>
                    <ListItemText primary="info@cfhlogistics.com" />
                  </ListItem>
                </List>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              
              <List component="nav">
                <ListItem button component="a" href="https://www.instagram.com/cfh_logistics/">
                  <ListItemIcon className={classes.iconFooter}>
                    <FontAwesomeIcon icon={faInstagram} size="2x"/>
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Fragment>
                        <Typography variant="body1">Instagram</Typography>
                        <Typography variant="body2" className={classes.secondText}>@cfh_logistics</Typography>
                      </Fragment>
                      } />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem button component="a" href="https://www.facebook.com/cfhlogistics/">
                  <ListItemIcon className={classes.iconFooter}>
                    <FontAwesomeIcon icon={faFacebook} size="2x"/>
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Fragment>
                        <Typography variant="body1">Facebook</Typography>
                        <Typography variant="body2" className={classes.secondText}>/cfhlogistics</Typography>
                      </Fragment>
                      } />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem button component="a" href="https://wa.me/17867752556?text=Contacto%20Web%20Virtue%20Distributors">
                  <ListItemIcon className={classes.iconFooter}>
                    <FontAwesomeIcon icon={faWhatsapp} size="2x"/>
                  </ListItemIcon>
                  <ListItemText 
                    primary={
                      <Fragment>
                        <Typography variant="body1">WhatsApp</Typography>
                        <Typography variant="body2" className={classes.secondText}>+1(786)775.2556</Typography>
                      </Fragment>
                      } />
                </ListItem>
                <Divider variant="inset" component="li" />
              </List>
            </Grid>
          </Grid>
          <Grid container className={classes.gridF}>
            <Grid item xs={12} >
              <img src={logoW} alt="logoW"  className={classes.logoW} />
            </Grid>
            <Grid item xs={12} > 
              <Typography variant="caption" >&copy; {new Date().getFullYear()} Copyright: CFH Logistics LLC</Typography>
            </Grid>
          </Grid>
        </Box>
      </Fragment>
    </section>  
    
  );
}