import ReactDOM from 'react-dom';
import React, { Fragment } from 'react';
import { BrowserRouter as Router , Route, Switch, Redirect } from 'react-router-dom';
import GuardRoute from './components/guardRoute';
import Root from './components/root';

import { AuthContextProvider } from './context/auth';
import { PpalListContextProvider } from './context/ppalList';

import Home from './pages/Home';
import DashBoard from './pages/Dashboard';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Shipping from './pages/Shipping';
import Page404 from './pages/Page404';

import './style.css';

const root = (
    <Fragment>
        <Router>
          <AuthContextProvider>
            <PpalListContextProvider>
                <Root>
                  <Switch>
                  <GuardRoute type="public" path="/home" component={Home} />
                  <GuardRoute type="public" path="/login" component={Login} />
                  <GuardRoute type="public" path="/signup" component={SignUp} />
                  <GuardRoute type="public" path="/shipping" component={Shipping} />
                  <GuardRoute type="private" path="/dashboard" component={DashBoard} />
                  <Redirect from="/" to="/home" />
                  <Route component={Page404} />
                  </Switch>
                </Root>
            </PpalListContextProvider>
          </AuthContextProvider>
        </Router>
    </Fragment>
    );


ReactDOM.render(root, document.getElementById('root'));


