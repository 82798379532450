import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Button,
    Card,
    CardActions, 
    CardContent,
    CardHeader,
    Grid,
    Typography,
} from '@material-ui/core';
import Layout from '../layout/Layout';
import HomeIcon from '@material-ui/icons/Home';
import '../layout/style.css';

const useStyles = makeStyles({
    root: {
      display: 'flex',
      minWidth: 300,
    },
    boxTypo: {
        backgroundColor: '#1e2935',
        padding: '2%',
        color: 'white',
        textAlign: 'left',
    }
  });

export default function Shipping() {
    const classes = useStyles();

    return (
    <Layout>
        <Grid container className={classes.root} justify="center">
            <Grid item>
                <Card variant="outlined">
                <CardHeader
                    title="Instructions for shipping and receiving:"
                    className={classes.boxTypo}
                    >
                </CardHeader>
                <CardContent>
                    <Typography variant="h6">1. Use the following address to shipments:</Typography>
                    <Typography variant="body2" color="primary">(Los envíos deben tener la siguiente dirección:)</Typography>
                    <Typography variant="body1">
                        14350 nw 56 ct unit 109 <br/>
                        Opa Locka FL 33054.
                        <br/>
                        <br/>
                    </Typography>
                    <Typography variant="h6">2. Packages must come in the name of:</Typography>
                    <Typography variant="body2" color="primary">(Los paquetes deben venir a nombre de:)</Typography>
                    <Typography variant="body1">
                        NOMBRE APELLIDO - NÚMERO DE CASILLERO<br/>
                        Barbara Salazar - VE06969<br/>
                        <br/>
                        NOMBRE DE EMPRESA -  NÚMERO DE CASILLERO<br/>
                        Mikropresente - US09999
                        <br/>
                        <br/>
                    </Typography>
                    <Typography variant="h6">3. Once the packages are received in our Miami warehouse, they are measured and weighed.
                    <small>They are then registered in our system and the warehouse receipt receipt is automatically sent by email.</small>                  
                    </Typography>
                    <Typography variant="body2" color="primary">(Una vez se reciben los paquetes en nuestro almacén de Miami, los mismos se miden y se pesan. 
                    Luego se registran en nuestro sistema y automáticamente es enviado el recibo de recepción de almacén por email.)</Typography>
                    <Typography variant="body1">Warehouse Receipt - WHR 00000.</Typography>
                </CardContent>
                <CardActions>
                    <Button 
                        name="subscribe"
                        color="primary"
                        startIcon={<HomeIcon />}
                        type="submit"
                        variant="text"
                        href="http://cfhlogistics.com/en/"
                    >
                    Ok
                    </Button>
                </CardActions>
                </Card>
            </Grid>      
        </Grid>
    </Layout>
    );
}

