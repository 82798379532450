import React, {Component} from 'react';
import { NavLink, withRouter } from "react-router-dom";
import { AppBar, Toolbar, Box, Button } from '@material-ui/core';
import { AuthContext } from '../context/auth';
import db from '../firebase/setup';

import logo from '../assets/logoFullHor.png';
import './style.css';

class Navbar extends Component {
  render() {
    const {
      isLoggedIn,
    } = this.context;
    return (
    <section id='navbar'>
      <Box className="rootNavbar">
        <AppBar position="sticky">
          <Toolbar className="barra">
            <Box 
              flexGrow={1} 
              display={{ xs: 'none', sm: 'block' }}
            >
              <img src={logo} alt="logo" className="logo" width="250" />
            </Box>
            <Button href="http://cfhlogistics.com/en/">Home</Button>
            {
              isLoggedIn &&
              <Button>
                <NavLink className="navLink" to="/" onClick={() => db.auth().signOut()} >Log Out</NavLink>
              </Button>
            }
            {
              !isLoggedIn &&
              <div>
                <Button>
                  <NavLink className="navLink" to="/signup">Sign Up</NavLink>
                </Button>
                <Button>
                  <NavLink className="navLink" to="/login">Log In</NavLink>
                </Button>
              </div>
            }
          </Toolbar>
        </AppBar>
      </Box>
    </section>
    );
  }   
}

Navbar.contextType = AuthContext;

export default withRouter(Navbar);
