import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
    createRow,
    deleteRow,
    updateRow
} from '../firebase/api';
import { withStyles } from '@material-ui/core/styles';
import {
    Box,
    Button,
    Dialog, 
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField,
    Typography,
    } from '@material-ui/core';

import CancelIcon from '@material-ui/icons/Cancel';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import DeleteIcon from '@material-ui/icons/Delete';
import { verifyUser } from '../verifyUser';
import { Formik, Form} from 'formik';

const styles = theme => ({
    header: {
        backgroundColor: '#1e2935',
        padding: '2%',
        color: 'white',
        textAlign: 'left',
      }
});

const countryCategory = [
    {
      value: "",
      label: "None"
    },
    {
      value: "US",
      label: "United States"
    },
    {
      value: "VE",
      label: "Venezuela"
    },
    {
      value: "CO",
      label: "Colombia"
    },
    {
      value: "PA",
      label: "Panama"
    }
  ];

class Detail extends Component {
    state = {
        showReload: false,
    }

    

    UNSAFE_componentWillReceiveProps(newProps) {
        const newDetail = newProps.detail;
        const oldDetail = this.props.detail;

        if (
            newDetail && oldDetail && (
                newDetail.fname !== oldDetail.fname ||
                newDetail.lname !== oldDetail.lname ||
                newDetail.email !== oldDetail.email ||
                newDetail.contact !== oldDetail.contact ||
                newDetail.country !== oldDetail.country ||
                newDetail.countryDest !== oldDetail.countryDest ||
                newDetail.addressDest !== oldDetail.addressDest ||
                newDetail.stateDest !== oldDetail.stateDest ||
                newDetail.cityDest !==  oldDetail.cityDest ||
                newDetail.contactDest !== oldDetail.contactDest ||
                newDetail.phoneDest !== oldDetail.phoneDest
            )
        ) {
            this.setState({showReload: true});
        }
    }

    onSubmit =async(values, formikBag) =>{
        const {
            detail,
            onClose,
        } = this.props;

        
        if (detail.id) {
            await updateRow(detail.id, values);
        } else {
            if (await verifyUser(values.email, values)) {
                await createRow(values)
                alert('Congratulation! your Mailbox was created, in a few minutes you will get receive an email with the number of your Mailbox confirmation');

            } else {
                alert('Usuario ya se encuentra registrado');
            }
        }
        
        formikBag.setSubmitting(false);
        onClose();

    }
    
    onDelete = async() => {
        const {
            detail,
            onClose,
        } = this.props;
        await deleteRow(detail.id);
        onClose();
    }

    onCancel = () => {
        this.props.onClose();
    }


    render() {
    const {classes} = this.props;
    const { 
        open,
        detail,
    } = this.props;
    const {
        showReload
    } = this.state;

    
    const node = (
        <div>
            <Dialog 
                open={open} 
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
            >
            <DialogTitle 
                id="responsive-dialog-title"
                className={classes.header}
            >
            {detail.id && 
                "Profile Details/Detalles del Perfil"
                        }
            {!detail.id &&
                "Create Profile/Crear Perfil"
                        }
            </DialogTitle>
            <Formik 
                initialValues={{
                    mailbox: detail.mailbox || '',
                    email: detail.email || '',
                    fname: detail.fname ||'',
                    lname: detail.lname ||'',
                    contact: detail.contact ||'',
                    country: detail.country ||'',
                    countryDest: detail.countryDest || '',
                    addressDest: detail.addressDest || '',
                    stateDest: detail.stateDest || '',
                    cityDest: detail.cityDest || '',
                    contactDest: detail.contactDest || '',
                    phoneDest: detail.phoneDest || '',
                }}
                onSubmit={this.onSubmit}
            >
                {props => {
                    const {
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        handleReset,
                        isSubmitting,
                        isValid,
                        values,
                    } = props;

                    return (
                    <Form>
                    <DialogContent>
                        <TextField
                            id="mailbox"
                            name="mailbox"
                            value={values.mailbox}
                            type="input"
                            onBlur={handleBlur('mailbox')}
                            onChange={handleChange('mailbox')}
                            label="Mailbox"
                            fullWidth
                        />
                        <Grid container>
                            <Grid item xs={12} md={6}>
                            <Box mr={1}>
                            <TextField
                                id="fname"
                                name="fname"
                                value={values.fname}
                                type="input"
                                onBlur={handleBlur('fname')}
                                onChange={handleChange('fname')}
                                label="Name/Nombre"
                                fullWidth
                            />
                            </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>    
                            <TextField
                                id="lname"
                                name="lname"
                                value={values.lname}
                                type="input"
                                onBlur={handleBlur('lname')}
                                onChange={handleChange('lname')}
                                label="Last Name/Apellido"
                                fullWidth
                            />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                            <Box mr={1}>
                            <TextField
                                id="email"
                                name="email"
                                value={values.email}
                                type="email"
                                onBlur={handleBlur('email')}
                                onChange={handleChange('email')}
                                label="Email"
                                fullWidth
                            />
                            </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>    
                            <TextField
                                id="contact"
                                name="contact"
                                value={values.contact}
                                type="input"
                                onBlur={handleBlur('contact')}
                                onChange={handleChange('contact')}
                                label="Contact Phone/Contacto"
                                fullWidth
                            />
                            </Grid>
                        </Grid>
                        <TextField
                            id="country"
                            name="country"
                            select
                            value={values.country}
                            label="Country/Pais"              
                            onBlur={handleBlur('country')}
                            onChange={handleChange('country')}
                            fullWidth
                        >
                            {countryCategory.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                            ))}
                        </TextField>
                        <Box my={2} >
                            <Typography variant="h6" component="h6">
                                Shipping Instruction
                            </Typography>
                        </Box>
                        <TextField 
                            id="addressDest"
                            name="addressDest"
                            value={values.addressDest}
                            onBlur={handleBlur('addressDest')}
                            onChange={handleChange('addressDest')}
                            fullWidth
                            label="Address/Dirección"
                        />
                        <Grid  container>
                            <Grid item xs={12} md={6}>
                            <Box mr={1}>
                                <TextField 
                                    id="stateDest"
                                    name="stateDest"
                                    value={values.stateDest}
                                    onBlur={handleBlur('stateDest')}
                                    onChange={handleChange('stateDest')}
                                    label="State/Estado"
                                    fullWidth
                                />
                            </Box>
                                
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField 
                                    id="cityDest"
                                    name="cityDest"
                                    value={values.cityDest}
                                    onBlur={handleBlur('cityDest')}
                                    onChange={handleChange('cityDest')}
                                    label="City/Ciudad"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            id="countryDest"
                            name="countryDest"
                            select
                            value={values.countryDest}
                            label="Country/Pais"              
                            onBlur={handleBlur('countryDest')}
                            onChange={handleChange('countryDest')}
                            fullWidth
                        >
                            {countryCategory.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                            ))}
                        </TextField>
                        <Grid  container>
                            <Grid item xs={12} md={6}>
                            <Box mr={1}>
                                <TextField 
                                    id="contactDest"
                                    name="contactDest"
                                    value={values.contactDest}
                                    onBlur={handleBlur('contactDest')}
                                    onChange={handleChange('contactDest')}
                                    label="Contact/Contacto Entrega"
                                    fullWidth
                                />
                            </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                 <TextField 
                                    id="phoneDest"
                                    name="phoneDest"
                                    value={values.phoneDest}
                                    onBlur={handleBlur('phoneDest')}
                                    onChange={handleChange('phoneDest')}
                                    label="Phone/Número Contacto"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>


                        {showReload && !isSubmitting &&
                            <Grid>
                                <Typography variant='caption'>
                                    **This resource has been updated. 
                                    <Button
                                        onClick={() => {
                                            handleReset();
                                            this.setState({ showReload: false });
                                            }}
                                    >
                                    Click here
                                    </Button>to Refresh <br />
                                    **Este recurso ha sufrido cambios. Haz Clic para Refrescar
                                </Typography>
                            </Grid>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={this.onCancel}
                            startIcon={<CancelIcon />}
                            variant="contained"
                        >
                        Cancel
                        </Button>
                        <Button 
                            color="primary"
                            disabled={isSubmitting || !isValid}
                            onClick={handleSubmit}
                            startIcon={<SaveAltIcon />}
                            variant="contained"
                        >
                        {detail.id && 
                            "Update"
                        }
                        {!detail.id &&
                            "Create"
                        }
                        </Button>
                        {detail.id &&
                            <Button
                                color="secondary"
                                startIcon={<DeleteIcon />}
                                variant="contained"
                                onClick={this.onDelete}
                            >
                            Delete
                            </Button>
                        }
                    </DialogActions>
                    </Form> 
                    );}}
            </Formik>
            </Dialog>
        </div>
    );
    
    return ReactDOM.createPortal(node, document.getElementById('modal-root'));
    }
}

Detail.defaultProps = {
    detail: {},
};

export default withStyles(styles)(Detail);

