import { auth, db } from './setup';


export function watchUserChanges(callback) {
    const unsub = auth.onAuthStateChanged((user) => {
        if (user && !user.isAnonymous) {
            callback({
                id: user.id,
                email: user.email,
                displayName: user.displayName,
            });
        } else {
            console.log('Not Logged in');
            callback(null);
        }
    });

    return unsub;
}


export function watchPpalList(callback) {
    const unsub = db.collection('mailboxes').onSnapshot((snapshot) => {
        let docs = []
        snapshot.forEach((doc) => {
            const data = doc.data()
            docs.push({ ...data, id: doc.id, });
        });

        let user = {};
        docs = docs.filter(o => user[o.email] ? false : user[o.email] = true);
        callback(docs);
    });

    return unsub;
}
