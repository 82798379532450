import React, { Component, Fragment } from 'react';
import { PpalListContext } from '../context/ppalList';
import { withStyles } from '@material-ui/core/styles';
import Detail from './Detail';
import { Box, Fab, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
import '../layout/style.css';
import MaterialTable from 'material-table';

const styles = theme => ({
  contact: {
    maxWidth: "100px"
  },
  email: {
    maxWidth: "200px"
  },
  footButton: {
    margin: theme.spacing(1),
  },
  mailbox: {
    maxWidth: "50px"
  },
  name: {
    maxWidth: "90px"
  },
  table: {
    minWidth: "650px"
  },
});



class DashBoard extends Component {
  state = {
    detailId: null,
    setOpen: false,

    rowsPerPage: 5,
    page: 0,
    Offset: 0,
    tempData: []
  }

  componentDidMount() {
    this.getData();
  }

  handleOpen() {
    this.setState({ setOpen: true });
  }

  getData = () => {
    let { Offset, rowsPerPage: Limit } = this.state;
    let tempArr = [];
    for (let i = Offset; i < Offset + Limit; i++) {
      tempArr.push(PpalListContext[i]);
    }
    return this.setState({ tempData: tempArr });
  };

  render() {
    const { classes } = this.props;
    const { ppalList } = this.context;
    const { detailId, isOpen } = this.state;
    const detail = ppalList.find(n => n.id === detailId);

    const columns = [
      {
        title: 'MailBox',
        field: 'mailbox'
      },
      {
        title: 'Name',
        field: 'fname'
      },
      {
        title: 'Last Name',
        field: 'lname'
      },
      {
        title: 'Email',
        field: 'email'
      },
      {
        title: 'Contact',
        field: 'contact'
      },
      {
        title: 'Country',
        field: 'country'
      }
    ]



    return (
      <Fragment>
        <Navbar />
        <Box display="flex" flexDirection="column" className="rootDashboard" p={1}>
          <Grid container justify="center">
            <Grid item xs={12} md={10} lg={7}>

              <MaterialTable
                columns={columns}
                data={ppalList}
                onRowClick={(event, rowData) => this.setState({ detailId: rowData.id, isOpen: true })}
                title={null}
                options={{
                  pageSize: 10,
                  pageSizeOptions: [10]
                }}
              />

            </Grid>
          </Grid>
          {isOpen &&
            <Detail
              detail={detail}
              onClose={() => this.setState({ detailId: null, isOpen: false })}
              open={true}
            />
          }
          <Grid container justify="center">
            <Grid item xs={12} md={10} lg={7}>
              <Fab
                className={classes.footButton}
                color="secondary"
                onClick={() => this.setState({ isOpen: true, detailId: null })}
                aria-label="add">
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </Fragment>
    );

  }
}

DashBoard.contextType = PpalListContext;

export default withStyles(styles)(DashBoard);