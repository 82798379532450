import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core';

import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Layout from '../layout/Layout';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { verifyUser } from '../verifyUser';
import { createRow } from '../firebase/api';

const styles = theme => ({
    header: {
        backgroundColor: '#1e2935',
        padding: '2%',
        color: 'white',
        textAlign: 'left',
    }
});

const countryCategory = [
    {
        value: "",
        label: "None"
    },
    {
        value: "US",
        label: "United States"
    },
    {
        value: "VE",
        label: "Venezuela"
    },
    {
        value: "CO",
        label: "Colombia"
    },
    {
        value: "PA",
        label: "Panama"
    }
];

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const formSchema = yup.object().shape({
    fname: yup.string()
        .min(3, 'Must have at least 3 characters.')
        .max(20, 'Must be shorter than 20 characters.')
        .required('Name is required.'),
    lname: yup.string()
        .min(3, 'Must have at least 3 characters.')
        .max(20, 'Must be shorter than 20 characters.')
        .required('Last Name is required.'),
    email: yup.string()
        .email('Enter a valid email.')
        .required('Email is required.'),
    contact: yup.string()
        .matches(phoneRegExp, 'Phone number is not valid.')
        .required('Phone number is required.'),
    country: yup.string()
        .required('Select a Country.'),
    countryDest: yup.string()
        .required('Select a Country.'),
    stateDest: yup.string()
        .min(3, 'Must have at least 3 characters.')
        .max(20, 'Must be shorter than 20 characters.')
        .required('State is required.'),
    cityDest: yup.string()
        .min(3, 'Must have at least 3 characters.')
        .max(20, 'Must be shorter than 20 characters.')
        .required('City is required.'),
    contactDest: yup.string()
        .min(3, 'Must have at least 3 characters.')
        .max(20, 'Must be shorter than 20 characters.')
        .required('Contact is required.'),
    phoneDest: yup.string()
        .min(3, 'Must have at least 3 characters.')
        .max(20, 'Must be shorter than 20 characters.')
        .required('Phone(s) is required.'),
    terms: yup.bool()
        .oneOf([true], 'You have to agree with our Terms and Conditions!',
    )
        .required('You have to agree with our Terms and Conditions!'),
})


class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            supportID: {},
            supportIdPlus: 0,
        };
    }

    onSubmit = async (values, { resetForm }) => {
        if (await verifyUser(values.email)) {
            await createRow(values)
            alert('Congratulation! your Mailbox was created, in a few minutes you will get receive an email with the number of your Mailbox confirmation');
            resetForm();
            this.props.history.push('/shipping');
        } else {
            alert('User is already registered');
            resetForm();
        }

    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Layout>
                    <Box
                        display="flex"
                        alignItems="center"
                        height="800px">
                        <Grid container justify="center" >
                            <Grid item xs={12} md={5}>
                                <Card >
                                    <CardHeader
                                        title="Sign UP"
                                        className={classes.header}
                                    />
                                    <Formik
                                        initialValues={{
                                            dateCreate: '',
                                            mailbox: '',
                                            email: '',
                                            fname: '',
                                            lname: '',
                                            contact: '',
                                            country: '',
                                            countryDest: '',
                                            addressDest: '',
                                            stateDest: '',
                                            cityDest: '',
                                            contactDest: '',
                                            phoneDest: '',
                                            terms: false,
                                        }}
                                        validationSchema={formSchema}
                                        onSubmit={this.onSubmit}

                                    >
                                        {props => {
                                            const {
                                                errors,
                                                handleBlur,
                                                handleChange,
                                                handleReset,
                                                isSubmitting,
                                                isValid,
                                                setFieldValue,
                                                touched,
                                                values,
                                            } = props;

                                            return (
                                                <Form noValidate>
                                                    <CardContent>
                                                        <Grid container>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    id="dateCreate"
                                                                    name="dateCreate"
                                                                    value={values.dateCreate}
                                                                    onBlur={handleBlur('dateCreate')}
                                                                    onChange={handleChange('dateCreate')}
                                                                    label="Date"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    id="mailbox"
                                                                    name="mailbox"
                                                                    value={values.mailbox}
                                                                    onBlur={handleBlur('mailbox')}
                                                                    onChange={handleChange('mailbox')}
                                                                    label="Mailbox"
                                                                    disabled
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12} md={6}>
                                                                <Box mr={1}>
                                                                    <TextField
                                                                        id="mce-fname"
                                                                        name="fname"
                                                                        value={values.fname}
                                                                        onBlur={handleBlur('fname')}
                                                                        onChange={handleChange('fname')}
                                                                        error={touched.fname && Boolean(errors.fname)}
                                                                        helperText={touched.fname ? errors.fname : ""}
                                                                        label="Name/Nombre"
                                                                        fullWidth
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    id="mce-lname"
                                                                    name="lname"
                                                                    value={values.lname}
                                                                    onBlur={handleBlur('lname')}
                                                                    onChange={handleChange('lname')}
                                                                    error={touched.lname && Boolean(errors.lname)}
                                                                    helperText={touched.lname ? errors.lname : ""}
                                                                    label="Last Name/Apellido"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item xs={12} md={6}>
                                                                <Box mr={1}>
                                                                    <TextField
                                                                        id="mce-email"
                                                                        name="email"
                                                                        value={values.email}
                                                                        type="email"
                                                                        onBlur={handleBlur('email')}
                                                                        onChange={handleChange('email')}
                                                                        error={touched.email && Boolean(errors.email)}
                                                                        helperText={touched.email ? errors.email : ""}
                                                                        label="Email"
                                                                        fullWidth
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    id="mce-contact"
                                                                    name="contact"
                                                                    value={values.contact}
                                                                    onBlur={handleBlur('contact')}
                                                                    onChange={handleChange('contact')}
                                                                    error={touched.contact && Boolean(errors.contact)}
                                                                    helperText={touched.contact ? errors.contact : ""}
                                                                    label="Contact Phone/Contacto"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <TextField
                                                            id="mce-country"
                                                            name="country"
                                                            select
                                                            value={values.country}
                                                            label="Country/Pais"
                                                            onBlur={handleBlur('country')}
                                                            onChange={handleChange('country')}
                                                            error={touched.country && Boolean(errors.country)}
                                                            helperText={touched.country ? errors.country : ""}
                                                            fullWidth
                                                        >
                                                            {countryCategory.map(option => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                        <Box my={2} >
                                                            <Typography variant="h6" component="h6">
                                                                Shipping Instruction
                                                            </Typography>
                                                        </Box>
                                                        <TextField
                                                            id="addressDest"
                                                            name="addressDest"
                                                            value={values.addressDest}
                                                            onBlur={handleBlur('addressDest')}
                                                            onChange={handleChange('addressDest')}
                                                            error={touched.addressDest && Boolean(errors.addressDest)}
                                                            helperText={touched.addressDest ? errors.addressDest : ""}
                                                            fullWidth
                                                            label="Address/Dirección"
                                                        />
                                                        <Grid container>
                                                            <Grid item xs={12} md={6}>
                                                                <Box mr={1}>
                                                                    <TextField
                                                                        id="stateDest"
                                                                        name="stateDest"
                                                                        value={values.stateDest}
                                                                        onBlur={handleBlur('stateDest')}
                                                                        onChange={handleChange('stateDest')}
                                                                        label="State/Estado"
                                                                        error={touched.stateDest && Boolean(errors.stateDest)}
                                                                        helperText={touched.stateDest ? errors.stateDest : ""}
                                                                        fullWidth
                                                                    />
                                                                </Box>

                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    id="cityDest"
                                                                    name="cityDest"
                                                                    value={values.cityDest}
                                                                    onBlur={handleBlur('cityDest')}
                                                                    onChange={handleChange('cityDest')}
                                                                    label="City/Ciudad"
                                                                    error={touched.cityDest && Boolean(errors.cityDest)}
                                                                    helperText={touched.cityDest ? errors.cityDest : ""}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <TextField
                                                            id="countryDest"
                                                            name="countryDest"
                                                            select
                                                            value={values.countryDest}
                                                            label="Country/Pais"
                                                            onBlur={handleBlur('countryDest')}
                                                            onChange={handleChange('countryDest')}
                                                            error={touched.countryDest && Boolean(errors.countryDest)}
                                                            helperText={touched.countryDest ? errors.countryDest : ""}
                                                            fullWidth
                                                        >
                                                            {countryCategory.map(option => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                        <Grid container>
                                                            <Grid item xs={12} md={6}>
                                                                <Box mr={1}>
                                                                    <TextField
                                                                        id="contactDest"
                                                                        name="contactDest"
                                                                        value={values.contactDest}
                                                                        onBlur={handleBlur('contactDest')}
                                                                        onChange={handleChange('contactDest')}
                                                                        label="Contact/Contacto Entrega"
                                                                        error={touched.contactDest && Boolean(errors.contactDest)}
                                                                        helperText={touched.contactDest ? errors.contactDest : ""}
                                                                        fullWidth
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <TextField
                                                                    id="phoneDest"
                                                                    name="phoneDest"
                                                                    value={values.phoneDest}
                                                                    onBlur={handleBlur('phoneDest')}
                                                                    onChange={handleChange('phoneDest')}
                                                                    error={touched.phoneDest && Boolean(errors.phoneDest)}
                                                                    helperText={touched.phoneDest ? errors.phoneDest : ""}
                                                                    label="Phone/Número Contacto"
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <FormControlLabel
                                                            control={<Checkbox />}
                                                            id="terms"
                                                            name='terms'
                                                            checked={values.terms}
                                                            onBlur={handleBlur('terms')}
                                                            onChange={() => setFieldValue("terms", !values.terms)}
                                                            type="checkbox"
                                                            label="I accept the terms and conditions."
                                                        />
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            onClick={handleReset}
                                                            startIcon={<CancelIcon />}
                                                            variant="text"
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            id="mc-embedded-subscribe"
                                                            name="subscribe"
                                                            color="primary"
                                                            disabled={isSubmitting || !isValid}
                                                            startIcon={<SaveAltIcon />}
                                                            type="submit"
                                                            variant="text"
                                                        >
                                                            Create
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            startIcon={<LocalLibraryIcon />}
                                                            variant="text"
                                                        >
                                                            <NavLink className="navLink" to="/shipping">Terms and Conditions</NavLink>
                                                        </Button>
                                                    </CardActions>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </Layout>
            </div>
        );

    }
}


export default (withStyles(styles)(withRouter(SignUp)));

