import { db } from './firebase/setup'

export async function verifyUser(email) {
    const mailbox = db.collection('mailboxes');
    const snapshot = await mailbox.where('email', '==', email).get();
    if (snapshot.empty) {
        return true;
    } else {
        return false;
    }
}
