import React, { Component, Fragment } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
import './style.css';
import { Box } from '@material-ui/core';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

class Layout extends Component {
    render() {
        const {
            children,
        } = this.props;
        
        return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <Navbar />
                <Box p={1} className="root">
                {children}
                </Box>
                <Footer />
            </ThemeProvider>
        </Fragment>
        );
    }
}

export default Layout;