import React, { Component } from 'react';
import { watchPpalList, watchUserChanges } from '../firebase/watcher';

export const PpalListContext = React.createContext();

export class PpalListContextProvider extends Component {
  state = {
    ppalList: [],
    listTwo: [],
  }

  componentDidMount() {
    watchUserChanges((user) => {
      if (user && !this.isSetup) {
        this.isSetup = true;

        watchPpalList((ppalList) => {
          this.setState({ ppalList });
        });


      }
    });

  }

  render() {
    return (
      <PpalListContext.Provider value={this.state}>
        {this.props.children}
      </PpalListContext.Provider>
    )
  }

}

export const PpalListContextConsumer = PpalListContext.Consumer;